<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
}
</script>
<style scoped>
#app{
  background: #F2F2F2;
}
</style>
