const routers = [
  {
    path: '/',
    component: () => import('@/components/Layout/VendorProxyLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/proxy/proxyHome.vue')
      },
      {
        path: '/embed',
        component: () => import('@/views/proxy/proxyEmbed.vue')
      },
      {
        path: '/about',
        component: () => import('@/views/proxy/proxyAbout.vue')
      }      
    ]
  },
]
export default routers
